






















import {get} from 'lodash';
import {Vue, Component} from 'vue-property-decorator';
import Title from "@/components/Title.vue";
import ContentBox from '@/components/ContentBox.vue';
import Version from "@/components/Version.vue";

@Component({
  components: {Version, Title, ContentBox}
})
export default class Versions extends Vue {

  manualDisable = false;

  get versions() {
    return get(this, '$store.state.versions.list', [])
  }

  async refresh() {
    await this.$store.dispatch('versions/updateVersions');
    this.$toast.success('VERSION LIST REFRESHED');
  }
}
